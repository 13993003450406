.Logo {
  position: sticky;
  width:100%;
  top:0px;
  left:1%;
  margin-bottom:10px;
  margin-top:10px;
  background-color: black;
}
a:link { text-decoration: none; }
a:visited { text-decoration: none; }
a:hover { text-decoration: underline; }
a:active { text-decoration: underline; }
.link{
  color:white;
  font-size: 3vw;
  padding:2%;
}
.App-header {

  width:100%;
  color: white;
  background-color: black;
}

.hub {

  align-items: flex-end;
  flex-direction: row;
}

.parent {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
.img {
    text-align: center;
    display: block;
    justify-content: center;
    align-items: center;
    margin: auto;
    max-height:80vh;
    width: auto;
    max-width: 80vw;
    padding-bottom: 10vh;
    padding-top:5vh;
}
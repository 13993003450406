body {

  background: black;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.hub {
  display: block;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 4vw;
 
  padding: 0px;
}

.hub span:nth-child(2) {
  background: #FF9900;
  color: #000000;
  border-radius: .444vw;
  padding: 0 .444vw .444vw .444vw;
  display: inline-block;
}